import React, { useContext } from "react"
import { navigate } from "gatsby"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
// import { ActivityFeed } from "../templates/ActivityFeed"
import AssetListingGroups from "../templates/AssetListingGroups"
const Home = props => {
  const { userType, authentication } = useContext(AuthenticationContext)

  if (
    userType.brandManager &&
    authentication.currentUserData.companies.length
  ) {
    navigate("/dashboard/my-assets")
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
      </div>
    )
  }
  return <AssetListingGroups />
}
export default Home
